<template>
  <div id="app">
    <Row> </Row>
    <Row>
      <Col span="24">
        <div style="padding: 20px; background: #eee" class="bb">
          <Card :bordered="false">
            <p slot="title">参数设定</p>
            <Button type="info" slot="extra" @click="generateConfig">生成配置文件</Button>
            <Row>
              <Col span="10">
                <div class="ew-form">
                  <Row>
                    <Col span="12">
                    </Col>
                    <Col span="12">
                    </Col>
                  </Row>
                  <div>
                    龙头针数({{configJSON.needles}}):
                    <Input type="number" number placeholder="输入针数" style="width: auto" v-model="configJSON.needles"/>
                  </div>
                  <div>
                    输出端口({{configJSON.outputPort}}):
                    <Input type="number" number placeholder="输入端口数量" style="width: auto" v-model="configJSON.outputPort"/>
                  </div>
                  <div>
                    圆盘每纬触发次数({{configJSON.sensorOutTotalCount}}):
                    <Input type="number" number placeholder="圆盘每纬触发次数" style="width: auto" v-model="configJSON.sensorOutTotalCount"/>
                  </div>
                  <div>
                    关闭电磁阀时机(约为上一个参数的三分之一):
                    <Input type="number" number placeholder="输入针数" style="width: auto" v-model="configJSON.sensorOutCountTrigger"/>
                  </div>
                  <div class="moto-param">
                    <div>
                      滚筒:
                      <i-switch v-model="rollerEnabled">
                        <span slot="open">开</span>
                        <span slot="close">关</span>
                      </i-switch>
                      <i-switch v-if="rollerEnabled" v-model="configJSON.rollerCCW" size="large" style="margin-left: 10px;" :true-value="1" :false-value="0"> 
                        <span slot="open">反转</span>
                        <span slot="close">正转</span>
                      </i-switch>
                    </div>
                    <Row v-if="rollerEnabled">
                      <Col span="8">
                        周长:
                        <Input type="number" number v-model="configJSON.rollerPerimeter" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        减速比
                        <Input type="number" number v-model="configJSON.rollerReductionRatio" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        伺服分辨率:
                        <Input type="number" number v-model="configJSON.rollerServoMotorRatio" style="width: auto;">
                      </Input>
                      </Col>
                    </Row>
                  </div>
                  <div class="moto-param">
                    <div>
                      底线:
                      <i-switch v-model="threadEnabled">
                        <span slot="open">开</span>
                        <span slot="close">关</span>
                      </i-switch>
                      <i-switch v-if="threadEnabled" v-model="configJSON.threadCCW" size="large" style="margin-left: 10px;" :true-value="1" :false-value="0">
                        <span slot="open">反转</span>
                        <span slot="close">正转</span>
                      </i-switch>
                    </div>
                    <Row v-if="threadEnabled">
                      <Col span="8">
                        周长:
                        <Input type="number" number v-model="configJSON.threadPerimeter" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        减速比
                        <Input type="number" number v-model="configJSON.threadReductionRatio" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        伺服分辨率:
                        <Input type="number" number v-model="configJSON.threadServoMotorRation" style="width: auto;">
                      </Input>
                      </Col>
                    </Row>
                  </div>
                  <div class="moto-param">
                    <div>
                      上送纬:
                      <i-switch v-model="topSendWeftEnabled">
                        <span slot="open">开</span>
                        <span slot="close">关</span>
                      </i-switch>
                      <i-switch v-if="topSendWeftEnabled" v-model="configJSON.topSendWeftCCW" size="large" style="margin-left: 10px;" :true-value="1" :false-value="0">
                        <span slot="open">反转</span>
                        <span slot="close">正转</span>
                      </i-switch>
                    </div>
                    <Row v-if="topSendWeftEnabled">
                      <Col span="8">
                        周长:
                        <Input type="number" number v-model="configJSON.topSendWeftPerimeter" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        减速比
                        <Input type="number" number v-model="configJSON.topSendWeftReductionRatio" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        伺服分辨率:
                        <Input type="number" number v-model="configJSON.topSendWeftServoMotorRatio" style="width: auto;">
                      </Input>
                      </Col>
                    </Row>
                  </div>
                  <div class="moto-param">
                    <div>
                      下送纬:
                      <i-switch v-model="bottomSendWeftEnabled">
                        <span slot="open">开</span>
                        <span slot="close">关</span>
                      </i-switch>
                      <i-switch v-if="bottomSendWeftEnabled" v-model="configJSON.bottomSendWeftCCW" size="large" style="margin-left: 10px;" :true-value="1" :false-value="0">
                        <span slot="open">反转</span>
                        <span slot="close">正转</span>
                      </i-switch>
                    </div>
                    <Row v-if="bottomSendWeftEnabled">
                      <Col span="8">
                        周长:
                        <Input type="number" number v-model="configJSON.bottomSendWeftPerimeter" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        减速比
                        <Input type="number" number v-model="configJSON.bottomSendWeftReductionRatio" style="width: auto;">
                        </Input>
                      </Col>
                      <Col span="8">
                        伺服分辨率:
                        <Input type="number" number v-model="configJSON.bottomSendWeftServoMotorRatio" style="width: auto;">
                      </Input>
                      </Col>
                    </Row>
                  </div>
                  <!-- <div>
                    滚筒减速比({{rollerReductionRatio}}):
                    <Input type="number" number placeholder="输入针数" style="width: auto" v-model="rollerReductionRatio"/>
                  </div>
                  <div>
                    滚筒电机分辨率({{rollerServoMotorRatio}}):
                    <Input type="number" number placeholder="输入针数" style="width: auto" v-model="rollerServoMotorRatio"/>
                  </div> -->
                  
                </div>
                
              </Col>
              <Col span="14">
                <Row>
                  <Col span="12">
                    <div class="ew-form">
                      <div>
                        排针表: 
                        <RadioGroup v-model="dragonMapType" @on-change="generateDragonMap" type="button">
                          <Radio :label="1">类型1</Radio>
                          <Radio :label="2">类型2</Radio>
                          <Radio :label="3">类型3</Radio>
                        </RadioGroup>
                      </div>
                      <div>
                        模组类型({{configJSON.moduleType}}):
                        <RadioGroup v-model="configJSON.moduleType" type="button">
                          <Radio :label="0">单通道</Radio>
                          <Radio :label="1">双通道</Radio>
                        </RadioGroup>
                      </div>
                    </div>
                    
                  </Col>
                  <Col span="12">
                    <div style="width: 200px">
                      <Row v-for="(items, index) in dragonMapShow" :key="index">
                        <Col v-for="item of items" :key="item" span="3">
                          {{ item }}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div>
                  
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Col>
    </Row>
    <Modal v-model="errModal" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-information-circle"></Icon>
        <span>参数错误</span>
      </p>
      <div style="text-align:center">
        <p>{{ errMsg }}</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="closeModal">关闭</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      
      threadEnabled: false,
      rollerEnabled: false,
      topSendWeftEnabled: false,
      bottomSendWeftEnabled: false,

      dragonMapType: 0,
      dragonMap: [],

      dragonMapShow: [],
      
      errMsg: "",
      errModal: false,
      configJSONStr: "",
      configJSON: {
        cmd: 20,
        requestKey: "c0GoCqQRqR9L4B6IUZLJRT0gekJVvN4x",
        needles: 320,
        outputPort: 5,
        insNeedles: 0,
        insStart: 0,
        reverseWeft: false,
        mask: [
          0,
          0
        ],
        dragonMap: [
          62, 63, 46, 47, 30, 31, 14, 15,
          60, 61, 44, 45, 28, 29, 12, 13,
          58, 59, 42, 43, 26, 27, 10, 11,
          56, 57, 40, 41, 24, 25, 8, 9,
          54, 55, 38, 39, 22, 23, 6, 7,
          52, 53, 36, 37, 20, 21, 4, 5,
          50, 51, 34, 35, 18, 19, 2, 3,
          48, 49, 32, 33, 16, 17, 0, 1],
        moduleType: 1,
        rollerPerimeter: 251.32,
        rollerReductionRatio: 77,
        rollerServoMotorRatio: 10000,
        rollerCCW: 0,
        rollerInsStart: 0,
        threadPerimeter: 117.8,
        threadReductionRatio: 3,
        threadServoMotorRation: 10000,
        threadCCW: 0,
        threadInsStart: 0,
        topSendWeftPerimeter: 141.37,
        topSendWeftReductionRatio: 2,
        topSendWeftServoMotorRatio: 10000,
        topSendWeftCCW: 1,
        topSendWeftInsStart: 0,
        bottomSendWeftPerimeter: 132.24,
        bottomSendWeftReductionRatio: 1,
        bottomSendWeftServoMotorRatio: 10000,
        bottomSendWeftCCW: 1,
        bottomSendWeftInsStart: 0,
        sensorOutCountTrigger: 4,
        sensorOutTotalCount: 12
      },
      downloadHref: ""
    };
  },
  methods: {
    
    generateDragonMap () {
      if (this.dragonMapType == 1) {
        return this.generateNeedlesListType1()
      } else if (this.dragonMapType == 2) {
        return this.generateNeedlesListType2()
      } else if (this.dragonMapType == 3) {
        return this.generateNeedlesListType3()
      }
    },
    generateNeedlesListType1 () {
      if (!this.checkNeedlesAndOutputPort()) {
        return false
      }
      let newMap = [];
      let portNeedles = this.configJSON.needles / this.configJSON.outputPort;
      let moduleNum = portNeedles / 8;
      for (let i = 0; i < moduleNum; i++) {
        newMap.push([])
      }
      
      let index = 0;
      
      for (let i = 3; i >= 0; i--) {
        for (let mn = (moduleNum - 1); mn >= 0; mn--) {
          newMap[mn][2*i] = index;
          index++;
          newMap[mn][2*i+1] = index;
          index++;
        }
      }
      let map = []
      for (let mn = 0; mn < moduleNum; mn++) {
        for (let y = 0; y < 8; y++) {
          map.push(newMap[mn][y]);
        }
      }
      this.dragonMapShow = newMap;
      this.dragonMap = map;
      return true;
    },
    generateNeedlesListType2 () {
      if (!this.checkNeedlesAndOutputPort()) {
        return false
      }
      let newMap = [];
      let portNeedles = this.configJSON.needles / this.configJSON.outputPort;
      let moduleNum = portNeedles / 8;
      for (let i = 0; i < moduleNum; i++) {
        newMap.push([])
      }

      let index = 0;

      for (let b = 7; b >= 0; b--) {
        for (let xp = (moduleNum -1); xp >= 0 ; xp--) {
            newMap[xp][b] = index;
            index++;
        }
      }
      let map = []
      for (let mn = 0; mn < moduleNum; mn++) {
        for (let y = 0; y < 8; y++) {
          map.push(newMap[mn][y]);
        }
      }
      this.dragonMapShow = newMap;
      this.dragonMap = map;
      return true;
    },
    generateNeedlesListType3 () {
      if (!this.checkNeedlesAndOutputPort()) {
        return false
      }
      let newMap = [];
      let portNeedles = this.configJSON.needles / this.configJSON.outputPort;
      let moduleNum = portNeedles / 8;
      for (let i = 0; i < moduleNum; i++) {
        newMap.push([])
      }
      let index = 0;
      for (let i = 1; i >= 0; i--) {
        for (let mn = (moduleNum - 1); mn >= 0; mn--) {
          newMap[mn][4*i] = index;
          index++;
          newMap[mn][4*i+1] = index;
          index++;
          newMap[mn][4*i+2] = index;
          index++;
          newMap[mn][4*i+3] = index;
          index++;
        }
      }
      let map = []
      for (let mn = 0; mn < moduleNum; mn++) {
        for (let y = 0; y < 8; y++) {
          map.push(newMap[mn][y]);
        }
      }
      this.dragonMapShow = newMap;
      this.dragonMap = map;
      return true;
    },
    checkNeedlesAndOutputPort() {
      if ((this.configJSON.needles % (this.configJSON.outputPort * 8)) != 0) {
        this.errModal = true;
        this.errMsg = "针数或输出端口数量错误";
        this.dragonMapType = 0;
        return false
      }
      return true
    },
    closeModal () {
      this.errModal = false;
      this.errMsg = "";
    },
    generateConfig () {

      if (this.dragonMapType == 0) {
        this.errModal = true;
        this.errMsg = "请选择排针表类型";
        return;
      }
      let b = this.generateDragonMap();
      if (b == false) {
        return false;
      }
      let insDataOffset = this.configJSON.needles;
      this.configJSON.dragonMap = this.dragonMap;
      // roller
      if (this.rollerEnabled) {
        if (this.configJSON.rollerServoMotorRatio == 0) {
          this.errModal = true;
          this.errMsg = "滚筒电机分辨率不能为0";
          return;
        }
        this.configJSON.rollerInsStart = insDataOffset;
        insDataOffset = insDataOffset + 4;
      } else {
        this.configJSON.rollerServoMotorRatio = 0;
        this.configJSON.rollerCCW = 0;
      }
      // thread
      if (this.threadEnabled) {
        if (this.configJSON.threadServoMotorRation == 0) {
          this.errModal = true;
          this.errMsg = "底线电机分辨率不能为0";
          return;
        }
        this.configJSON.threadInsStart = insDataOffset;
        insDataOffset += 4;
      } else {
        this.configJSON.threadServoMotorRation = 0;
        this.configJSON.threadCCW = 0;
      }
      // top send weft
      if (this.topSendWeftEnabled) {
        if (this.configJSON.topSendWeftServoMotorRatio == 0) {
          this.errModal = true;
          this.errMsg = "上送纬电机分辨率不能为0";
          return;
        }
        this.configJSON.topSendWeftInsStart = insDataOffset;
        insDataOffset += 5;
      } else {
        this.configJSON.topSendWeftServoMotorRatio = 0;
        this.configJSON.topSendWeftCCW = 0;
      }
      // bottom send weft
      if (this.bottomSendWeftEnabled) {
        if (this.configJSON.bottomSendWeftServoMotorRatio == 0) {
          this.errModal = true;
          this.errMsg = "下送纬电机分辨率不能为0";
          return;
        }
        this.configJSON.bottomSendWeftInsStart = insDataOffset;
      } else {
        this.configJSON.bottomSendWeftServoMotorRatio = 0;
        this.configJSON.bottomSendWeftCCW = 0;
      }
      this.configJSONStr = JSON.stringify(this.configJSON)

      var eleLink = document.createElement('a');
      eleLink.download = "config.json";
      eleLink.style.display = 'none';
      var blob = new Blob([this.configJSONStr]);
      eleLink.href = URL.createObjectURL(blob);
      document.body.appendChild(eleLink);
      eleLink.click();
      console.log(this.configJSONStr)
      document.body.removeChild(eleLink);
    }
  },
  created() {
    // this.showDragonMap();
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
.ew-form div {
  /* margin-top: 10px; */
  padding: 2px;
}
.moto-param {
  border: 1px solid #000
}
.bb {
  /* height: 720px; */
  overflow: scroll;
}
</style>
